export default defineNuxtRouteMiddleware((to, from) => {
  const fromName = (from.name as string)
  if (fromName.includes('swap'))
    return true

  const { config } = useAppState()
  const name = (to.name as string)
  const enableExchange = computed(() => !!config?.value?.exchange?.enable_exchange)
  if (name.includes('swap') && enableExchange.value)
    return true

  // ����ȫ�ֵ���
  globalModal.SwapClosePrompt?.open?.()

  return abortNavigation()
})
